import React from "react";
import FeaturesSplit from "../components/sections/FeaturesSplit";

const Tretmani = () => {
    return (
        <>
            <FeaturesSplit />
        </>
    );
};

export default Tretmani;
